#content {
  top: 0;
}

.introduction {
  min-height: 100vh;
  top: 0;
  margin-top: 0;
  position: sticky;
  background-color: #f47c7c;
  margin-top: 0;
}

.projects {
  margin: 0 auto;
  min-height: 100vh;
  top: 0;
  position: sticky;
  background-color: #a1de93;
  margin: 0;
  /* display: block; */
  overflow: auto;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  top: 0;
  position: sticky;
  background-color: #70a1d7;
  margin: 0;
}

.image {
  /* background-color: #fde26c; */
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  top: 0;
  position: sticky;
  margin: 0;
}

.image img {
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.aboutMe {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: #fde26c; */
  background-color: white;
  margin: 0 auto;
  width: 80vw;
  min-height: 80vh;
  /* padding-bottom: 10vh; */
  top: 0;
  position: sticky;
  /* margin: 0; */
}

@media (max-width: 900px) {
  .aboutMe {
    width: 100vw;
    /* font-size: 1rem; */
  }
}

@media (max-width: 500px) {
  .projects {
    position: relative;
  }
}

h1 {
  margin-top: 0;
}
