@import url("https://fonts.googleapis.com/css?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css?family=Mansalva&display=swap");

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.intro {
  font-family: "Mansalva", cursive;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  width: 50vw;
  line-height: 1.6;
  letter-spacing: 0.05rem;
}

.contact {
  margin-top: 4rem;
  padding: 2rem 5rem;
  background-color: #f47c7c;
  color: white;
  border: 0.15rem solid white;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Abel", sans-serif;
  letter-spacing: 0.25rem;
  transition: 1s;
  cursor: pointer;
  outline: none;
}

.contact:hover {
  background-color: white;
  color: #f47c7c;
  transition: 1s;
}

.highlight {
  color: white;
}

.arrow {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.arrow span {
  display: block;
  width: 50px;
  height: 50px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
}
.arrow span:nth-child(1) {
  animation: animate 3s infinite;
  opacity: 0;
  animation-delay: -0.5s;
}

.arrow:hover span {
  border-bottom: 5px solid #a1de93;
  border-right: 5px solid #a1de93;
  transition: 1s;
}

.arrow:hover span:nth-child(1) {
  animation: fade 1s;
}

/* laptop  */
@media (max-width: 1500px) {
  .info {
    justify-content: flex-start;
  }
  .intro {
    margin-top: 15vh;
  }
  .arrow {
    bottom: 1%;
  }
}

/* small laptop */
@media (max-width: 1200px) {
  .intro {
    width: 70vw;
    margin-bottom: 10vh;
  }
  .contact {
    font-size: 2rem;
  }
  .arrow {
    bottom: 10vh;
  }
}

/* tablet */
@media (max-width: 830px) {
  .intro {
    width: 75vw;
    margin-bottom: 10%;
    margin-top: 2rem;
  }
  .contact {
    /* margin-top: 1rem; */
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  .intro {
    width: 90vw;
    margin-top: 10vh;
    margin-bottom: 9%;
    font-size: 2.5rem;
  }
  .contact {
    margin-top: 0;
  }
  .arrow {
    bottom: 4vh;
  }
  .arrow span {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 380px) {
  .intro {
    width: 100vw;
    margin-top: 5%;
    margin-bottom: 14%;
    font-size: 2.5rem;
  }
  .arrow {
    bottom: 2vh;
  }
}

@media (max-width: 360px) {
  .intro {
    width: 100vw;
    margin-top: 4vh;
    margin-bottom: 20%;
    font-size: 2rem;
  }
}

@media (max-width: 340px) {
  .intro {
    width: 100vw;
    margin-top: 3vh;
    margin-bottom: 9%;
    font-size: 2rem;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}
