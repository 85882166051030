@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.container {
  width: 100%;
  height: 100%;
  /* background-color: white; */
  background: inherit;
  /* filter: blur(10px); */
  position: absolute;
  /* margin: 1% auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  border-radius: 10px;
}

.container h2 {
  /* margin-top: 5vh; */
  font-size: 2rem;
  color: #de384f;
  font-family: "Quicksand", sans-serif;
}

.container p {
  color: #515151;
  width: 40vw;
  margin-top: 5vh;
  font-size: 1.4rem;
  font-family: "Playfair Display", serif;
}

#ps {
  font-size: 1rem;
}

.red {
  font-size: 1.6rem;
  color: #de384f;
}

.arrow {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.arrow span {
  display: block;
  width: 50px;
  height: 50px;
  border-bottom: 5px solid #909090;
  border-right: 5px solid #909090;
  transform: rotate(45deg);
  margin: -10px;
}
.arrow span:nth-child(1) {
  animation: animate 3s infinite;
  opacity: 0;
  animation-delay: -0.5s;
}

.arrow:hover span {
  border-bottom: 5px solid #70a1d7;
  border-right: 5px solid #70a1d7;
  transition: 1s;
}

.arrow:hover span:nth-child(1) {
  animation: fade 1s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}
@media (max-width: 1500px) {
  .container p {
    margin-top: 2vh;
    font-size: 1.3rem;
  }
  .container h2 {
    margin-top: -12vh;
  }
}

@media (max-width: 1200px) {
  .container h2 {
    margin-top: -10vh;
  }
  .container p {
    margin-top: 1.5vh;
    font-size: 1.4rem;
  }
}

@media (max-width: 900px) {
  .container p {
    width: 90vw;
    font-size: 1rem;
  }
  .container h2 {
    margin-top: -15vh;
  }
}
