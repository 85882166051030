* {
  margin: 0;
  padding: 0;
}
body {
  font-family: arial, sans-serif;
  font-size: 100%;
  /* margin: 3em; */
  /* background: #666;
  color: #fff; */
}
@import url("https://fonts.googleapis.com/css?family=Reenie+Beanie&display=swap");

h2,
p {
  font-size: 100%;
  font-weight: normal;
}
ul,
li {
  list-style: none;
}
ul {
  overflow: hidden;
  padding: 3rem;
  /* margin-bottom: 15%; */
}
ul li a {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  height: 15rem;
  width: 15rem;
  padding: 1rem;
  /* Firefox */
  -moz-box-shadow: 5px 5px 7px rgba(33, 33, 33, 1);
  /* Safari+Chrome */
  -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  /* Opera */
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  transition: transform linear;
  -moz-transition: -moz-transform 0.15s linear;
  -o-transition: -o-transform 0.15s linear;
  -webkit-transition: -webkit-transform 0.15s linear;
}
ul li {
  margin: 1em;
  float: left;
}

ul li h2 {
  font-size: 140%;
  font-weight: bold;
  padding-bottom: 10px;
}
ul li p {
  font-family: "Reenie Beanie", cursive;
  font-size: 180%;
}

ul li:nth-child(even) a {
  transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  position: relative;
  top: 5px;
  background: #cfc;
}
ul li:nth-child(3n) a {
  transform: rotate(-3dey);
  -o-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  position: relative;
  top: -5px;
  background: #ccf;
}
ul li:nth-child(5n) a {
  transform: rotate(5deg);
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  top: -10px;
}

ul li a:hover,
ul li a:focus {
  -moz-box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  position: relative;
  z-index: 5;
}

.projectsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.projectsHeader {
  font-family: "Reenie Beanie", cursive;
  font-size: 4rem;
  margin-top: 5vh;
  font-weight: bold;
  color: white;
}

.arrow {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.arrow span {
  display: block;
  width: 50px;
  height: 50px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
}
.arrow span:nth-child(1) {
  animation: animate 3s infinite;
  opacity: 0;
  animation-delay: -0.5s;
}

.arrow:hover span {
  border-bottom: 5px solid #fde26c;
  border-right: 5px solid #fde26c;
  transition: 1s;
}

.arrow:hover span:nth-child(1) {
  animation: fade 1s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

/* mobile view */
@media (max-width: 750px) {
  ul {
    margin-bottom: 25vh;
  }
  ul li {
    float: none;
  }
}
