@import url("https://fonts.googleapis.com/css?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");

.hello {
  position: relative;
  top: 30%;
  width: 50%;
  margin: auto;
  color: white;
  padding: 2rem;
  border: 0.15rem solid white;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Abel", sans-serif;
  letter-spacing: 0.25rem;
  transform: scale(1);
  animation: pulse 4s infinite;
}

.flipCard {
  background-color: transparent;
  width: 30vw;
  height: 30vh;
  perspective: 1000px;
}

/* laptop and tablet size */
@media (max-width: 1500px) {
  .flipCard {
    width: 40vw;
    height: 35vh;
  }
}

@media (max-width: 1200px) {
  .hello {
    width: 70%;
  }
}

/* mobile size */
@media (max-width: 500px) {
  .flipCard {
    width: 85vw;
    height: 50vh;
  }
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipCard:hover .flipCardInner,
.flipCard:active .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCardFront {
  background-color: #70a1d7;
  color: black;
}

.flipCardBack {
  background-color: #fdfda6;
  color: black;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  border-radius: 10px;
}

.businessCard {
  margin-top: 0;
}

.businessCard h2 {
  font-size: 2rem;
  margin-top: 0;
  font-family: "Caveat", cursive;
}

.businessCard section {
  margin: 1% 5%;
}

.businessCard h3 {
  text-align: left;
  font-family: "Abel", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: bold;
  margin-bottom: 1%;
}

.businessCard p {
  text-align: left;
}

.top {
  position: absolute;
  /* margin-left: 90vw; */
  top: 2vh;
  right: 2vw;
  background-color: transparent;
  padding: 1rem;
  color: white;
  border: 1px solid white;
  border-radius: 60px;
  transition: 1s;
  font-size: 1rem;
  outline: none;
}

.top:hover {
  cursor: pointer;
  background-color: white;
  color: #70a1d7;
  transition: 1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.3rem rgba(255, 255, 255, 1);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
