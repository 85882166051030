.App {
  text-align: center;
  background-color: #f47c7c;
  height: 100vh;
  width: 100vw;
  position: absolute;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.window {
  background-color: white;
  width: 100vw;
  /* margin: auto; */
  /* border-radius: 10px; */
  height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.window::-webkit-scrollbar {
  display: none;
}
