@import url(https://fonts.googleapis.com/css?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css?family=Mansalva&display=swap);
@import url(https://fonts.googleapis.com/css?family=Reenie+Beanie&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.App {
  text-align: center;
  background-color: #f47c7c;
  height: 100vh;
  width: 100vw;
  position: absolute;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.window {
  background-color: white;
  width: 100vw;
  /* margin: auto; */
  /* border-radius: 10px; */
  height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.window::-webkit-scrollbar {
  display: none;
}

#Home_content__sgIvw {
  top: 0;
}

.Home_introduction__2Cm4C {
  min-height: 100vh;
  top: 0;
  margin-top: 0;
  position: -webkit-sticky;
  position: sticky;
  background-color: #f47c7c;
  margin-top: 0;
}

.Home_projects__SAQKM {
  margin: 0 auto;
  min-height: 100vh;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  background-color: #a1de93;
  margin: 0;
  /* display: block; */
  overflow: auto;
}

.Home_contact__x5cwO {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  background-color: #70a1d7;
  margin: 0;
}

.Home_image__2qLHx {
  /* background-color: #fde26c; */
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  margin: 0;
}

.Home_image__2qLHx img {
  position: relative;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.Home_aboutMe__En-iS {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: #fde26c; */
  background-color: white;
  margin: 0 auto;
  width: 80vw;
  min-height: 80vh;
  /* padding-bottom: 10vh; */
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  /* margin: 0; */
}

@media (max-width: 900px) {
  .Home_aboutMe__En-iS {
    width: 100vw;
    /* font-size: 1rem; */
  }
}

@media (max-width: 500px) {
  .Home_projects__SAQKM {
    position: relative;
  }
}

h1 {
  margin-top: 0;
}

.Introduction_info__2UWQG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Introduction_intro__1nMNF {
  font-family: "Mansalva", cursive;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  width: 50vw;
  line-height: 1.6;
  letter-spacing: 0.05rem;
}

.Introduction_contact__3fsz8 {
  margin-top: 4rem;
  padding: 2rem 5rem;
  background-color: #f47c7c;
  color: white;
  border: 0.15rem solid white;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Abel", sans-serif;
  letter-spacing: 0.25rem;
  transition: 1s;
  cursor: pointer;
  outline: none;
}

.Introduction_contact__3fsz8:hover {
  background-color: white;
  color: #f47c7c;
  transition: 1s;
}

.Introduction_highlight__3Qy1Q {
  color: white;
}

.Introduction_arrow__3PcNc {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.Introduction_arrow__3PcNc span {
  display: block;
  width: 50px;
  height: 50px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
}
.Introduction_arrow__3PcNc span:nth-child(1) {
  -webkit-animation: Introduction_animate__34qkI 3s infinite;
          animation: Introduction_animate__34qkI 3s infinite;
  opacity: 0;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.Introduction_arrow__3PcNc:hover span {
  border-bottom: 5px solid #a1de93;
  border-right: 5px solid #a1de93;
  transition: 1s;
}

.Introduction_arrow__3PcNc:hover span:nth-child(1) {
  -webkit-animation: Introduction_fade__3mmH4 1s;
          animation: Introduction_fade__3mmH4 1s;
}

/* laptop  */
@media (max-width: 1500px) {
  .Introduction_info__2UWQG {
    justify-content: flex-start;
  }
  .Introduction_intro__1nMNF {
    margin-top: 15vh;
  }
  .Introduction_arrow__3PcNc {
    bottom: 1%;
  }
}

/* small laptop */
@media (max-width: 1200px) {
  .Introduction_intro__1nMNF {
    width: 70vw;
    margin-bottom: 10vh;
  }
  .Introduction_contact__3fsz8 {
    font-size: 2rem;
  }
  .Introduction_arrow__3PcNc {
    bottom: 10vh;
  }
}

/* tablet */
@media (max-width: 830px) {
  .Introduction_intro__1nMNF {
    width: 75vw;
    margin-bottom: 10%;
    margin-top: 2rem;
  }
  .Introduction_contact__3fsz8 {
    /* margin-top: 1rem; */
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  .Introduction_intro__1nMNF {
    width: 90vw;
    margin-top: 10vh;
    margin-bottom: 9%;
    font-size: 2.5rem;
  }
  .Introduction_contact__3fsz8 {
    margin-top: 0;
  }
  .Introduction_arrow__3PcNc {
    bottom: 4vh;
  }
  .Introduction_arrow__3PcNc span {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 380px) {
  .Introduction_intro__1nMNF {
    width: 100vw;
    margin-top: 5%;
    margin-bottom: 14%;
    font-size: 2.5rem;
  }
  .Introduction_arrow__3PcNc {
    bottom: 2vh;
  }
}

@media (max-width: 360px) {
  .Introduction_intro__1nMNF {
    width: 100vw;
    margin-top: 4vh;
    margin-bottom: 20%;
    font-size: 2rem;
  }
}

@media (max-width: 340px) {
  .Introduction_intro__1nMNF {
    width: 100vw;
    margin-top: 3vh;
    margin-bottom: 9%;
    font-size: 2rem;
  }
}

@-webkit-keyframes Introduction_animate__34qkI {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes Introduction_animate__34qkI {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@-webkit-keyframes Introduction_fade__3mmH4 {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes Introduction_fade__3mmH4 {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: arial, sans-serif;
  font-size: 100%;
  /* margin: 3em; */
  /* background: #666;
  color: #fff; */
}

h2,
p {
  font-size: 100%;
  font-weight: normal;
}
ul,
li {
  list-style: none;
}
ul {
  overflow: hidden;
  padding: 3rem;
  /* margin-bottom: 15%; */
}
ul li a {
  text-decoration: none;
  color: #000;
  background: #ffc;
  display: block;
  height: 15rem;
  width: 15rem;
  padding: 1rem;
  /* Firefox */
  /* Safari+Chrome */
  /* Opera */
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  transform: rotate(-6deg);
  -webkit-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  transition: transform linear;
  -moz-transition: -moz-transform 0.15s linear;
  -o-transition: -o-transform 0.15s linear;
  -webkit-transition: -webkit-transform 0.15s linear;
}
ul li {
  margin: 1em;
  float: left;
}

ul li h2 {
  font-size: 140%;
  font-weight: bold;
  padding-bottom: 10px;
}
ul li p {
  font-family: "Reenie Beanie", cursive;
  font-size: 180%;
}

ul li:nth-child(even) a {
  transform: rotate(4deg);
  -o-transform: rotate(4deg);
  -webkit-transform: rotate(4deg);
  -moz-transform: rotate(4deg);
  position: relative;
  top: 5px;
  background: #cfc;
}
ul li:nth-child(3n) a {
  transform: rotate(-3dey);
  -o-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  position: relative;
  top: -5px;
  background: #ccf;
}
ul li:nth-child(5n) a {
  transform: rotate(5deg);
  -o-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  position: relative;
  top: -10px;
}

ul li a:hover,
ul li a:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
  position: relative;
  z-index: 5;
}

.Projects_projectsContainer__xB2Y1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Projects_projectsHeader__3kNGP {
  font-family: "Reenie Beanie", cursive;
  font-size: 4rem;
  margin-top: 5vh;
  font-weight: bold;
  color: white;
}

.Projects_arrow__2sKrw {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.Projects_arrow__2sKrw span {
  display: block;
  width: 50px;
  height: 50px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
}
.Projects_arrow__2sKrw span:nth-child(1) {
  -webkit-animation: Projects_animate__3l7JT 3s infinite;
          animation: Projects_animate__3l7JT 3s infinite;
  opacity: 0;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.Projects_arrow__2sKrw:hover span {
  border-bottom: 5px solid #fde26c;
  border-right: 5px solid #fde26c;
  transition: 1s;
}

.Projects_arrow__2sKrw:hover span:nth-child(1) {
  -webkit-animation: Projects_fade__K9AMR 1s;
          animation: Projects_fade__K9AMR 1s;
}

@-webkit-keyframes Projects_animate__3l7JT {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes Projects_animate__3l7JT {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@-webkit-keyframes Projects_fade__K9AMR {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes Projects_fade__K9AMR {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

/* mobile view */
@media (max-width: 750px) {
  ul {
    margin-bottom: 25vh;
  }
  ul li {
    float: none;
  }
}

.Contact_hello__2jmlX {
  position: relative;
  top: 30%;
  width: 50%;
  margin: auto;
  color: white;
  padding: 2rem;
  border: 0.15rem solid white;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Abel", sans-serif;
  letter-spacing: 0.25rem;
  transform: scale(1);
  -webkit-animation: Contact_pulse__4KPqF 4s infinite;
          animation: Contact_pulse__4KPqF 4s infinite;
}

.Contact_flipCard__3dQyf {
  background-color: transparent;
  width: 30vw;
  height: 30vh;
  perspective: 1000px;
}

/* laptop and tablet size */
@media (max-width: 1500px) {
  .Contact_flipCard__3dQyf {
    width: 40vw;
    height: 35vh;
  }
}

@media (max-width: 1200px) {
  .Contact_hello__2jmlX {
    width: 70%;
  }
}

/* mobile size */
@media (max-width: 500px) {
  .Contact_flipCard__3dQyf {
    width: 85vw;
    height: 50vh;
  }
}

.Contact_flipCardInner__3Ndx0 {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.Contact_flipCard__3dQyf:hover .Contact_flipCardInner__3Ndx0,
.Contact_flipCard__3dQyf:active .Contact_flipCardInner__3Ndx0 {
  transform: rotateY(180deg);
}

.Contact_flipCardFront__2QlPl,
.Contact_flipCardBack__1ytoi {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Contact_flipCardFront__2QlPl {
  background-color: #70a1d7;
  color: black;
}

.Contact_flipCardBack__1ytoi {
  background-color: #fdfda6;
  color: black;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  border-radius: 10px;
}

.Contact_businessCard__3Rejc {
  margin-top: 0;
}

.Contact_businessCard__3Rejc h2 {
  font-size: 2rem;
  margin-top: 0;
  font-family: "Caveat", cursive;
}

.Contact_businessCard__3Rejc section {
  margin: 1% 5%;
}

.Contact_businessCard__3Rejc h3 {
  text-align: left;
  font-family: "Abel", sans-serif;
  letter-spacing: 0.1rem;
  font-weight: bold;
  margin-bottom: 1%;
}

.Contact_businessCard__3Rejc p {
  text-align: left;
}

.Contact_top__2uWVi {
  position: absolute;
  /* margin-left: 90vw; */
  top: 2vh;
  right: 2vw;
  background-color: transparent;
  padding: 1rem;
  color: white;
  border: 1px solid white;
  border-radius: 60px;
  transition: 1s;
  font-size: 1rem;
  outline: none;
}

.Contact_top__2uWVi:hover {
  cursor: pointer;
  background-color: white;
  color: #70a1d7;
  transition: 1s;
}

@-webkit-keyframes Contact_pulse__4KPqF {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.3rem rgba(255, 255, 255, 1);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes Contact_pulse__4KPqF {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.3rem rgba(255, 255, 255, 1);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.About_container__2x2eq {
  width: 100%;
  height: 100%;
  /* background-color: white; */
  background: inherit;
  /* filter: blur(10px); */
  position: absolute;
  /* margin: 1% auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  border-radius: 10px;
}

.About_container__2x2eq h2 {
  /* margin-top: 5vh; */
  font-size: 2rem;
  color: #de384f;
  font-family: "Quicksand", sans-serif;
}

.About_container__2x2eq p {
  color: #515151;
  width: 40vw;
  margin-top: 5vh;
  font-size: 1.4rem;
  font-family: "Playfair Display", serif;
}

#About_ps__1UL9r {
  font-size: 1rem;
}

.About_red__3ElQP {
  font-size: 1.6rem;
  color: #de384f;
}

.About_arrow__984OP {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.About_arrow__984OP span {
  display: block;
  width: 50px;
  height: 50px;
  border-bottom: 5px solid #909090;
  border-right: 5px solid #909090;
  transform: rotate(45deg);
  margin: -10px;
}
.About_arrow__984OP span:nth-child(1) {
  -webkit-animation: About_animate__2p691 3s infinite;
          animation: About_animate__2p691 3s infinite;
  opacity: 0;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.About_arrow__984OP:hover span {
  border-bottom: 5px solid #70a1d7;
  border-right: 5px solid #70a1d7;
  transition: 1s;
}

.About_arrow__984OP:hover span:nth-child(1) {
  -webkit-animation: About_fade__19Ep- 1s;
          animation: About_fade__19Ep- 1s;
}

@-webkit-keyframes About_animate__2p691 {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes About_animate__2p691 {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@-webkit-keyframes About_fade__19Ep- {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}

@keyframes About_fade__19Ep- {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-0.5rem, -0.5rem);
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0.5rem, 0.5rem);
  }
}
@media (max-width: 1500px) {
  .About_container__2x2eq p {
    margin-top: 2vh;
    font-size: 1.3rem;
  }
  .About_container__2x2eq h2 {
    margin-top: -12vh;
  }
}

@media (max-width: 1200px) {
  .About_container__2x2eq h2 {
    margin-top: -10vh;
  }
  .About_container__2x2eq p {
    margin-top: 1.5vh;
    font-size: 1.4rem;
  }
}

@media (max-width: 900px) {
  .About_container__2x2eq p {
    width: 90vw;
    font-size: 1rem;
  }
  .About_container__2x2eq h2 {
    margin-top: -15vh;
  }
}

